import { Connection } from "./connection";
import { Widget } from "./widget";
import * as QueryString from 'query-string'

export class Space{  

    constructor(parsedJSONSpace, spaceManager){
        this.id = parsedJSONSpace.id;
        this.spaceManager = spaceManager;
        this.appManager = spaceManager.appManager;
        this.textureURL = parsedJSONSpace.imageUrl;
        this.videoURL = parsedJSONSpace.videoURL;
        this.name = parsedJSONSpace.title;
        this.left = parsedJSONSpace.left;
        this.top = parsedJSONSpace.top;
        this.oldPhotos = parsedJSONSpace.oldPhotos;
        this.hasQuickAccess = parsedJSONSpace.hasQuickAccess;
        this.dome = null;

        this.setJSONConnections(parsedJSONSpace.connections);
        this.setJSONWidgets(parsedJSONSpace.widgets);
    }

    setJSONWidgets(JSONWidgets) {

        this.widgets = [];
        if(!JSONWidgets)
            return;
        
        for(const widget of JSONWidgets) {
            this.widgets.push(
                new Widget(
                    widget,
                    this.appManager
                )
            );
        }
    }

    setJSONConnections(JSONConnections) {
        
        this.connections = [];
        if(!JSONConnections)
            return;
        
        for(const connection of JSONConnections) {
            this.connections.push(
                new Connection(
                    connection,
                    this.appManager
                )
            );
        }
    }

    disposeSpace(){
        this.dome.dispose(false, true);

        for(let connection of this.connections) {
            connection.dispose();
        }

        for(let widget of this.widgets){
            widget.dispose();
        }

        this.dome = null;
    }

    createDome(){
        const query = QueryString.parse(location.search);
        query.id = this.id;
        
        const url = window.location.pathname + '?' + QueryString.stringify(query); 
        window.history.pushState( { path:url }, '', url);

        this.appManager.htmlElements.currentSpaceLabel.innerHTML = this.name;

        if (this.videoURL){
            this.createVideoDome();
        } else {
            this.createImageDome();
        }

        this.onLoadObservableAdd(() => this.createHotspots());
    }

    createImageDome(){
        try {
            this.dome = new BABYLON.PhotoDome(
                "Dome_"+this.name,
                this.textureURL,
                {
                    /* resolution: 12, */
                    size: 100,
                    useDirectMapping: false
                },
                this.appManager.scene,
                ()=>{
                    console.log('1 failed')
                }
            )
          } catch (error) {
            console.error(error);
            // Expected output: ReferenceError: nonExistentFunction is not defined
            // (Note: the exact output may be browser-dependent)
          }

        this.createNadar(this.textureURL)
    }

    createVideoDome(){

        this.dome = new BABYLON.VideoDome(
            "Dome_"+this.name,
            this.videoURL,
            {
                resolution: 12,
                size: 100,
                useDirectMapping: false
            },
            this.appManager.scene
        );

        //TODO: check user interaction and unmute
        this.dome.videoTexture.video.muted = true; //allows autoplay when user has not interacted with DOM
        this.dome.videoTexture.video.play();
    }

    createHotspots() {
        this.createWidgets();
        this.createConnections();
        var loadingScreen = document.getElementById("loading-screen")
        if(loadingScreen.style.visibility !== "hidden")
            loadingScreen.style.visibility = "hidden" 
    }

    createWidgets() {
        for (let widget of this.widgets) {
            widget.createWidgetButton();
        } 
    }

    createConnections() {
        for (let connection of this.connections) {
            connection.createWidgetButton();
        } 
    }

    onLoadObservableAdd(callback) {
        this.dome.texture.onLoadObservable.add(callback);
    }

    hasLoaded() {
        return this.dome.texture.isReady();
    }

    getConnectionTo(space) {
        let connections = this.connections.filter(connection => connection.connectedSpaceName === space);

        if(connections.length > 0)
            return connections[0];
        
        return null;
    }

    getRandomConnection(){
        var randomConnection = this.connections[0];
        if(this.connections.length > 1){
            randomConnection = this.connections[this.randomNumber(0, this.connections.length)];
            this.checkIfSameSpace(randomConnection);
        }else{
            this.jumpToNewSpace(randomConnection);
        }    
    }

    checkIfSameSpace(randomConnection){
        for (var i = 0; i < this.spaceManager.spaces.length; i++) {
            if(this.spaceManager.spaces[i].name == randomConnection.connectedSpaceName){
                var desiredSpace = this.spaceManager.spaces[i];
            }
        }
        if(desiredSpace == this.spaceManager.previousSpace){
            this.getRandomConnection();
        }else{
            if(randomConnection.widgetButtonMesh != null)
                this.jumpToNewSpace(randomConnection);
        }
    }

    jumpToNewSpace(randomConnection){
        var self = this;
        this.appManager.introBot.startBot();
        if(this.appManager.introBot.isRunning == true && this.appManager.timerNewSpace == null){
            this.appManager.cameraManager.lookAtMeshSlowly(randomConnection.widgetButtonMesh);
            this.appManager.timerNewSpace = setTimeout(function() {
                if(self.appManager.introBot.isRunning == true){
                    self.spaceManager.updateSpaceByName(randomConnection.connectedSpaceName);
                }
            }, 4000);
        }
    }

    randomNumber(min, max){
        const r = Math.random()*(max-min) + min
        return Math.floor(r)
    }

    setWidgetSizes(isSmall){
        for (var i = 0; i < this.widgets.length; i++) {
            if(isSmall && this.widgets[i].widgetButtonMesh){
                this.widgets[i].animateWidgetScaleTo(0.6)//this.widgets[i].setWidgetScale("moving");
            }else if(!isSmall && this.widgets[i].widgetButtonMesh){
                this.widgets[i].animateWidgetScaleTo(1)//this.widgets[i].setWidgetScale("");
            }
        }
    }

    setVisibilityOfAllHotspots(isVisible) {

        for(const widget of this.widgets) {
            if(widget.widgetButtonMesh)
                widget.widgetButtonMesh.isVisible = isVisible;
        }

        for(const connection of this.connections) {
            connection.widgetButtonMesh.isVisible = isVisible;
        }
    }

    async createNadar(texture){
        const mat = new BABYLON.StandardMaterial("");
        mat.diffuseTexture = new BABYLON.Texture(texture);
        mat.opacityTexture = new BABYLON.Texture("./textures/icons/nadar.png");
        mat.emissiveTexture = mat.diffuseTexture
        mat.opacityTexture.coordinatesIndex = 1
        mat.diffuseTexture.wAng = Math.PI; 
        mat.emissiveTexture.wAng = Math.PI; 
        mat.diffuseTexture.vAng = Math.PI/2; 
        mat.emissiveTexture.vAng = Math.PI/2; 

        let plane = await BABYLON.SceneLoader.ImportMeshAsync("","./textures/icons/testNadar.glb");
        plane = plane.meshes[1]
        plane.scaling = new BABYLON.Vector3(2.3, 2.3, 2.3);
        plane.position = new BABYLON.Vector3(0,-5.5,0)
        plane.rotation.x = Math.PI/2
        plane.material = mat;
    }
}