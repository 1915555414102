import * as GUI from "babylonjs-gui";
import { Space } from "../Objects/space.model";
import * as QueryString from 'query-string';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const MOVE_THROUGH_SPACES_DELAY = 2000;

export class SpaceManager{  

    constructor(appManager){
        this.appManager = appManager;
        this.spaces = [];
        this.startingSpace = null;
        this.currentSpace = null;
        this.previousSpace = null;
        this.isLoadingSpace = null;

        this.isTimelineActive = false;
        this.timelineCarouselElements = [];
        this.timelineIndex = 0;
        this.timelineSwiper = null;
        
        this.initialise();
    }

    initialise(){
        this.createSpaces();
        this.setupJumpToButton();
        this.setupTimelineButton();
    }

    setupJumpToButton() {
        let htmlElements = this.appManager.htmlElements;

        htmlElements.jumpToButton.addEventListener("click", () => {
            this.setupJumpToSidebar();
        });
    }

    setupJumpToSidebar() {
        this.setupMapSidebar();
        /* let htmlElements = this.appManager.htmlElements;
        htmlElements.resetSidebarContent();

        let contentHeader = document.createElement('h2');
        contentHeader.innerHTML = "Jump me to";
        htmlElements.appendChildToSidebarContent(contentHeader);

        let self = this;
        let contentList = document.createElement('ul');
        let spaceNames = this.getQuickAccessSpaceNames();
        let count = 0;

        for(const name of spaceNames) {

            if(name == this.appManager.spaceManager.currentSpace.name)
                continue;
            
            let listItem = document.createElement('li');
            listItem.innerHTML += name;
            listItem.onclick = function() {
                htmlElements.setSidebarVisible(false);
                self.updateSpaceByName(name);
            };
            contentList.appendChild(listItem);

            listItem.classList.add('animateLoad');
            setTimeout(() => { listItem.classList.add('loaded') }, (50 * count));
            count++;
        }
        
        htmlElements.appendChildToSidebarContent(contentList); */
    }
    setupMapSidebar(){
        let htmlElements = this.appManager.htmlElements;
        htmlElements.resetSidebarContent();

        let contentList = document.createElement('div');
        contentList.src = this.appManager.uiElements.mapImage//"./textures/icons/mapView.png"
        contentList.classList.add('animateLoad');
        setTimeout(() => { contentList.classList.add('loaded') }, 150 );

        let map = document.createElement('img');
        map.id = "map"
        map.src = this.appManager.uiElements.mapImage//"./textures/icons/mapView.png"
        map.style.width = "100%"
        contentList.appendChild(map);

        let self = this;
        let spaceNames = this.getQuickAccessSpaceNames();
        let count = 0;

        //for(const name of spaceNames) {
        for(const space of this.spaces) {
            if (space.top == undefined) { continue; }
            let div = document.createElement('div');
            div.classList.add('btn_container');

            let button = null

            if(space.name == this.appManager.spaceManager.currentSpace.name){
                button = document.createElement('img');
                button.src = "./textures/icons/location.svg"
                button.classList.add('navigation-icon');
                button.style.cursor = "default";
            }
            else{
                button = document.createElement('div');
                button.classList.add('pulse-button-dark');
                button.style.cursor = "pointer";
                button.onclick = function() {
                    htmlElements.setSidebarVisible(false);
                    self.updateSpaceByName(space.name);
                };
            }
            div.appendChild(button);

            div.style.position = "fixed"
            div.style.top = space.top+"%";
            div.style.left = space.left+"%";

            
            contentList.appendChild(div);

            div.classList.add('animateLoad');
            setTimeout(() => { div.classList.add('loaded') }, (20 * count));
            count++;
        } 
        
        htmlElements.appendChildToSidebarContent(contentList);
    }

    setupTimelineButton() {
        let htmlElements = this.appManager.htmlElements;
        htmlElements.timelineButton.addEventListener("click", () => {

            if(this.isTimelineActive) {
                this.hideTimeline();
            }
            else {
                this.showTimeline();
            }
        });

        this.updateTimelineButtonVisibility();
    }

    createSpaces(){
        for (const space of this.appManager.parsedJSON.spaces) {
            this.spaces.push(
                new Space(
                    space,
                    this
                )
            );
        }
        this.startingSpace = this.getStartingSpace();
        this.currentSpace = this.startingSpace;
    }

    getStartingSpace() { 
        const query = QueryString.parse(location.search);

        if(!query.id)
            return this.spaces[0];

        return this.findSpaceByID(query.id) ?? this.spaces[0];
    }


    getQuickAccessSpaceNames() {
        let names = [];

        for(const space of this.spaces) {
            if(space.hasQuickAccess) {
                names.push(space.name);
            }
        }

        return names;
    }

    updateSpaceByName(spaceName){
        let space = this.findSpaceByName(spaceName)
        this.updateSpace(space)

        if(document.getElementById("map"))
            this.appManager.htmlElements.setSidebarVisible(false);
    }

    findSpaceByName(spaceName){
        for (var i = 0; i < this.spaces.length; i++) {
            if(this.spaces[i].name.toUpperCase() == spaceName.toUpperCase()){
                return this.spaces[i];
            }
        }
    }

    updateSpaceByID(spaceID){
        this.updateSpace(this.findSpaceByID(spaceID))
    }

    findSpaceByID(spaceID){

        let filtered = this.spaces.filter(space => space.id == spaceID);

        return filtered.length > 0 ? filtered[0] : null;


        for (var i = 0; i < this.spaces.length; i++) {
            if(this.spaces[i].id == spaceID){
                return this.spaces[i];
            }
        }
    }

    updateSpace(space){
        if(!this.checkIfLoadingScene(space)){
            this.clearSceneBesidesCurrentDome();
            this.isLoadingSpace = true;
            this.previousSpace = this.currentSpace;
            this.currentSpace = space;
            this.updateTimelineButtonVisibility();
            this.appManager.cameraManager.transitionOutEffect();

            space.createDome();
            space.onLoadObservableAdd(() => this.disposeLastSpace());

            this.appManager.timerNewSpace = null;
        }

        if(this.isTimelineActive) {
            this.hideTimeline();
        }
    }

    checkIfLoadingScene(space){
        if(!this.isLoadingSpace && space != this.currentSpace && space)
            return false
        else
            return true
    }

    hasCurrentSpaceLoaded() {
        return this.currentSpace.hasSpaceLoaded();
    }

    clearSceneBesidesCurrentDome(){
        var currentDome = this.appManager.scene.getMeshByID(this.currentSpace.dome.id+"_mesh")
        /* for (var i = 0; i < this.appManager.scene.meshes.length; i++) {
            this.appManager.scene.meshes[i].dispose(false,true)
        } */
        this.appManager.scene.meshes = [];
        this.appManager.scene.materials = [];
        this.appManager.scene.cleanCachedTextureBuffer();
        this.appManager.scene.clearCachedVertexData();
        this.appManager.scene.meshes.push(currentDome) 
    
    }

    disposeLastSpace(){
        if(this.previousSpace != null){
            this.appManager.cameraManager.transitionInEffect();
            this.previousSpace.disposeSpace(); 
        }
    }

    addNewOption(name){
        let dropdownItem = document.createElement('li');
        let dropdownContent = document.getElementById('sidebarList');

        dropdownContent.appendChild(dropdownItem);
        var self = this;
        dropdownItem.onclick = function() {
            self.appManager.pathfindingManager.beginUserGuidanceTo(name);
        };
        
        dropdownItem.innerHTML += name;
    }

    moveThroughSpaces(spaces, delay = MOVE_THROUGH_SPACES_DELAY) {

        this.lookAtConnectionFor(spaces[1]);
        for(let i=1; i < spaces.length; i++) {

            setTimeout(() => {
                //set space
                this.updateSpaceByName(spaces[i]);

                //look at the next space name widget mesh
                if(i < spaces.length - 1) {
                    this.lookAtConnectionFor(spaces[i + 1]);
                }
            }, delay * i);
        }
    }

    lookAtConnection(connection) {
        
        //if dome already loaded - look
        if(this.currentSpace.dome.texture.isReady()) {
            this.appManager.cameraManager.lookAtMeshSlowly(connection.widgetButtonMesh);
        }
        //otherwise wait for dome to load - then look
        else {
            this.currentSpace.dome.texture.onLoadObservable.add(() => {
                this.appManager.cameraManager.lookAtMeshSlowly(connection.widgetButtonMesh);
            });
        }
    }

    hideTimeline() {
        let htmlElements = this.appManager.htmlElements;

        this.loadTimelinePhoto(this.currentSpace.textureURL);

        //Show space UI
        this.currentSpace.setVisibilityOfAllHotspots(true);
        htmlElements.setVisible(htmlElements.hamburgerButton, true);
        htmlElements.setVisible(htmlElements.helpButton, true);
        
        //Hide timeline ui
        htmlElements.setVisible(htmlElements.timelineContainer, false);
        htmlElements.timelineButton.querySelector('.material-icons').innerHTML = 'history';
        
        //Reset timeline
        this.timelineSwiper.destroy(true, true);
        this.timelineIndex = 0;
        htmlElements.timeline.innerHTML = '';
        this.timelineCarouselElements = [];
        this.isTimelineActive = false;
    }

    showTimeline() {
        let htmlElements = this.appManager.htmlElements;

        //Hide space UI
        this.currentSpace.setVisibilityOfAllHotspots(false);
        htmlElements.setVisible(htmlElements.hamburgerButton, false);
        htmlElements.setVisible(htmlElements.helpButton, false);

        //Show timeline UI
        htmlElements.setVisible(htmlElements.timelineContainer, true);
        htmlElements.timelineButton.querySelector('.material-icons').innerHTML = 'close';
        
        this.setupTimeline();
        this.isTimelineActive = true;
    }

    updateTimelineButtonVisibility() {
        this.appManager.htmlElements.setVisible(this.appManager.htmlElements.timelineButton, this.currentSpace.oldPhotos != null);
    }

    setupTimeline() {
        let timelinePhotos = this.currentSpace.oldPhotos;
        timelinePhotos.sort((a,b) => new Date(a.dateTaken) - new Date(b.dateTaken));

        for (let i=0 ; i<timelinePhotos.length; i++) {
            let dateTaken = timelinePhotos[i].dateTaken;
            let url = timelinePhotos[i].photoURL;

            this.addTimelineListItem(url, new Date(dateTaken).toLocaleString().split(',')[0], i);
        }

        let currentTimelineIndex = timelinePhotos.length;
        this.timelineIndex = currentTimelineIndex;
        this.addTimelineListItem(this.currentSpace.textureURL, "Current", currentTimelineIndex);
        this.updateTimelineElements();

        this.setupSwiper();
    }

    setupSwiper() {
        this.timelineSwiper = new Swiper('.timeline-swiper', 
        {
            modules: [Navigation, Pagination],
            initialSlide: this.timelineIndex,
            direction: 'horizontal',
            centeredSlides: true,
            slidesPerView: 3,
            loop: false,
            grabCursor: true,
            breakpoints: {
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 5
                }
            }
        });

        this.timelineSwiper.on("slideChange", () => {
            const index_currentSlide = this.timelineSwiper.realIndex;

            if(this.timelineIndex != index_currentSlide)
                this.timelineCarouselElements[index_currentSlide].click();
        });

        for(let i=0 ; i<this.timelineCarouselElements.length; i++) {
            let carouselElement = this.timelineCarouselElements[i];
            carouselElement.addEventListener("click", () => {
                this.timelineSwiper.slideTo(i);
            });
        }
    }

    addTimelineListItem(url, title, index) {
        let htmlElements = this.appManager.htmlElements;
        let carouselElement = htmlElements.createTimelineListItem(title);
        
        carouselElement.addEventListener("click", () => {
            this.timelineIndex = index;
            this.loadTimelinePhoto(url);
            this.updateTimelineElements();
        });

        this.timelineCarouselElements.push(carouselElement);
        htmlElements.timeline.appendChild(carouselElement);
    }

    loadTimelinePhoto(url) {
        this.currentSpace.dome.texture = new BABYLON.Texture(url);
    }

    updateTimelineElements() {
        for(var i=0; i<this.timelineCarouselElements.length; i++) {
            this.timelineIndex == i
                ? this.timelineCarouselElements[i].classList.add('active') 
                : this.timelineCarouselElements[i].classList.remove('active');
        } 
    }
}