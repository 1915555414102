
export class PathfindingManager{  

    constructor(appManager){
        
        this.appManager = appManager;
        
        this.isGuiding = false;
        this.spacesPath = null;
        this.spacesPathIndex = 0;
        this.space = null;
        this.widgetObserver = null;
        this.guidanceCarouselElements = [];

        this.setupGuidanceButtons();
    }

    //UI
    setupGuidanceButtons() {
        let htmlElements = this.appManager.htmlElements;

        htmlElements.addEventListener(htmlElements.guidanceButton, () => {
            htmlElements.resetSidebarContent();
            this.setupGuidanceSidebar();
        });

        htmlElements.addEventListener(htmlElements.guidanceCancelButton, () => {
            this.stopUserGuidance();
            this.space.setVisibilityOfAllHotspots(true);
            this.setupGuidanceSidebar();
        });
    }

    setupGuidanceSidebar() {
        this.appManager.htmlElements.resetSidebarContent();

        if(this.isGuiding) {
            this.appManager.htmlElements.setSidebarVisible(true, false, true);
            this.setupGuidingToSidebar();
        }
        else {
            this.setupGuideToList();
        }
    }

    async setupGuidingUI() {
        let htmlElements = this.appManager.htmlElements;

        htmlElements.setSidebarVisible(false);
        htmlElements.setVisible(htmlElements.hamburgerButton, false);
        htmlElements.setVisible(htmlElements.timelineButton, false);
        htmlElements.guidanceCardLabel.innerHTML = this.getTargetSpace();
        htmlElements.spaceCard.classList.add('mdc-card');

        this.setupGuidanceCarousel();

        setTimeout(() => htmlElements.guidanceCard.classList.add('loaded'), 200);
    }

    async setupGuidanceCarousel() {
        let htmlElements = this.appManager.htmlElements;

        for (let i=0 ; i<this.spacesPath.length; i++) {
            let name = this.spacesPath[i];
            let space = this.appManager.spaceManager.findSpaceByName(name);

            let guidanceCarouselImage = htmlElements.createCarouselImage(space.textureURL);
            guidanceCarouselImage.classList.add('carousel-image');
            guidanceCarouselImage.addEventListener("click", () => {
                if(this.spacesPathIndex == i)
                    return;
                
                this.loadPathSpaceByIndex(i, true);
            });

            let guidanceCarouselWrapper = document.createElement('div');
            guidanceCarouselWrapper.classList.add('carousel-image-wrapper');
            guidanceCarouselWrapper.appendChild(guidanceCarouselImage);
            this.guidanceCarouselElements.push(guidanceCarouselWrapper);
            htmlElements.guidanceContainer.appendChild(guidanceCarouselWrapper);
        }

        this.updateguidanceCarouselElements();
    }

    setupGuideToList() {
        let htmlElements = this.appManager.htmlElements;

        let contentHeader = document.createElement('h2');
        contentHeader.innerHTML = "Guide me to";
        htmlElements.appendChildToSidebarContent(contentHeader);

        let self = this;
        let contentList = document.createElement('ul');
        let spaceNames = this.appManager.spaceManager.getQuickAccessSpaceNames();
        let count = 0;

        for(const name of spaceNames) {
            if(name == this.appManager.spaceManager.currentSpace.name)
                continue;
            
            let listItem = document.createElement('li');
            listItem.innerHTML += name;
            listItem.onclick = function() {
                self.beginUserGuidanceTo(name)    
            };
            contentList.appendChild(listItem);

            //animate load
            listItem.classList.add('animateLoad');
            setTimeout(() => { listItem.classList.add('loaded') }, (50 * count));
            count++;
        }

        contentHeader.classList.add('no-user-select');
        contentList.classList.add('no-user-select');
        
        htmlElements.appendChildToSidebarContent(contentList);
    }



    //logic
    beginUserGuidanceTo(spaceName) {
        this.isGuiding = true;
        //this.appManager.htmlElements.showDropdown(false);

        if(this.appManager.spaceManager.isTimelineActive) {
            this.appManager.spaceManager.hideTimeline();
        }

        let currentSpaceName = this.appManager.spaceManager.currentSpace.name;

        this.spacesPathIndex = 0;
        this.spacesPath = this.findShortestPathToSpaceByName(currentSpaceName, spaceName);

        if(!this.spacesPath) {
            console.error("Path could not be found between %s and %s.", currentSpaceName, spaceName);
            return;
        }
        
        this.loadPathSpaceByIndex(this.spacesPathIndex, false);
        this.setupGuidingUI();
    }

    stopUserGuidance() {

        let nextSpace = this.spacesPath[this.spacesPathIndex + 1];
        let connection = this.space.getConnectionTo(nextSpace);
        if(connection) {
            connection.widgetButton.onPointerClickObservable.remove(this.widgetObserver);
        }

        
        this.isGuiding = false;
        this.spacesPath = null;
        this.spacesPathIndex = 0;
        this.guidanceCarouselElements = [];
        this.appManager.htmlElements.setVisible(this.appManager.htmlElements.hamburgerButton, true);
        this.appManager.htmlElements.setVisible(this.appManager.htmlElements.timelineButton, true);
        this.appManager.htmlElements.guidanceContainer.innerHTML = '';
        this.appManager.htmlElements.guidanceCard.classList.remove('loaded');
        setTimeout(() => this.appManager.htmlElements.spaceCard.classList.remove('mdc-card'), 200);
    }

    setupUI() {
        const canvasWrap = document.getElementById("canvasWrap");
        
        let closeButton = this.addButton(canvasWrap, "close");
        let nextButton = this.addButton(canvasWrap, "next");
        let prevButton = this.addButton(canvasWrap, "prev");

        closeButton.style.top = "5%";
        closeButton.style.right = "5%";
        closeButton.style.width = "10vw"
        closeButton.style.height = "5vh";
        closeButton.addEventListener("click", () => {
            closeButton.remove();
            nextButton.remove();
            prevButton.remove();
            this.stopUserGuidance();
        });
        
        nextButton.style.bottom = "5%";
        nextButton.style.right = "5%";
        nextButton.addEventListener("click", () => this.loadNextSpace());
        
        prevButton.style.bottom = "5%";
        prevButton.style.left = "5%";
        prevButton.addEventListener("click", () => this.loadPreviousSpace());
    }

    updateguidanceCarouselElements() {
        if(this.guidanceCarouselElements == null)
            return;

        for (var i=0; i<this.guidanceCarouselElements.length; i++) {
            
            this.spacesPathIndex == i 
                ? this.guidanceCarouselElements[i].classList.add('Active') 
                : this.guidanceCarouselElements[i].classList.remove('Active');
        }
    }

    addButton(parent, text) {
        
        let button = document.createElement("button");
        button.setAttribute("id", text + "3Dbutton");
        button.textContent = text;
        button.style.width = "40vw";
        button.style.minWidth = "fit-content"
        button.style.height = "10vh";
        button.style.position = "absolute";

        button.style.fontSize = "large";
        
        button.style.backgroundColor = "white";
        button.style.borderRadius = "40px";
        button.style.border = "none";
        button.style.boxShadow = "0px 2px 5px black";

        button.addEventListener("mouseover", () => {
            button.style.backgroundColor = "lightgrey";
        });
        button.addEventListener("mouseout", () => {
            button.style.backgroundColor = "white";
        });

        parent.appendChild(button);

        return button;
    }

    getTargetSpace() {
        if(!this.spacesPath)
            return null;

        return this.spacesPath[this.spacesPath.length - 1];
    }

    loadNextSpace() {
        let newIndex = this.spacesPathIndex + 1;
        if(newIndex > this.spacesPath.length - 1)
            return;

        this.loadPathSpaceByIndex(newIndex, true);
    }

    loadPreviousSpace() {
        let newIndex = this.spacesPathIndex - 1;
        if(newIndex < 1)
            return;

        this.loadPathSpaceByIndex(newIndex, true);
    }

    loadPathSpaceByIndex(loadIndex, updateSpace) {
        this.spacesPathIndex = loadIndex;
        this.space = this.appManager.spaceManager.findSpaceByName(this.spacesPath[loadIndex]);
        this.updateguidanceCarouselElements();
        
        if(updateSpace) {
            this.appManager.spaceManager.updateSpace(this.space);
        }

        //if reached destination
        if(this.spacesPath.length - 1 == loadIndex) {
            this.stopUserGuidance();
            return;
        }

        this.prepareSpaceHotspots();
    }

    prepareSpaceHotspots() {

        let toDoImplementThisBetter = () => {
            this.space.setVisibilityOfAllHotspots(false);
            this.prepareConnectionToNextSpace();
        };

        if(this.space.hasLoaded()) {
            toDoImplementThisBetter();
        }
        else {
            this.space.onLoadObservableAdd(toDoImplementThisBetter);
        }
    }

    prepareConnectionToNextSpace() {

        if(this.spacesPathIndex > this.spacesPath.length - 1)
            return;

        let nextSpace = this.spacesPath[this.spacesPathIndex + 1];
        let connection = this.space.getConnectionTo(nextSpace);
        if(connection) {
            connection.widgetButtonMesh.isVisible = true;
            this.appManager.spaceManager.lookAtConnection(connection);
            this.widgetObserver = connection.widgetButton.onPointerClickObservable.add(() => {
                this.spacesPathIndex++;
                this.loadPathSpaceByIndex(this.spacesPathIndex, false);
            });
        }
    }


    //Pathfinding
    findShortestPathToSpaceByName(startSpace, goalSpace){

        if(startSpace === goalSpace) {
            return [startSpace];
        }

        let paths = [];
        let shortestPath = [];
        let visitedSpaceNames = [];
        let hasFinishedSearching = false;

        // add start space
        paths.push([startSpace]);
        visitedSpaceNames.push(startSpace);

        var getSpaceConnections = (spaceName) => {
            let space = this.appManager.spaceManager.findSpaceByName(spaceName);
            let connections = space.connections.map(connection => connection.connectedSpaceName);
            return connections.filter(spaceName => !visitedSpaceNames.includes(spaceName));
        }

        var getPathHeadConnections = (path) => {
            let pathHead = path[path.length - 1];
            return getSpaceConnections(pathHead);
        }

        var searchPaths = (paths) => {
            let newPaths = [];
            let connections = [];

            for(let path of paths) {
                
                connections = getPathHeadConnections(path);

                // if connections has goal return
                if(connections.includes(goalSpace)) {
                    hasFinishedSearching = true;
                    shortestPath = [...path, goalSpace];
                    return paths;
                }

                // add new paths of old path + each connection
                for(const connection of connections) {
                    newPaths.push(path.concat([connection]));
                    visitedSpaceNames.push(connection);
                }
            }

            // no more possible connections, stop and return null
            if(newPaths.length == 0) {
                hasFinishedSearching = true;
                return null;
            }
            
            // return new paths to check
            return [...newPaths];
        }

        //Check paths from this space
        while (!hasFinishedSearching) {
            paths = searchPaths(paths);
        }

        return shortestPath;
    }
}