import { PanelGroup } from "./panelGroup";

const TITLE_TEXT_ANIMATION_TOTAL_FRAMES = 20;

export class Widget{  
    constructor(parsedJSONWidget, appManager){
        this.appManager = appManager;
        this.iconList = {
            "audioIcon": "./textures/icons/audio.svg",
            "dataIcon": "./textures/icons/data.svg",
            "formIcon": "./textures/icons/form.svg",
            "imageIcon": "./textures/icons/image.svg",
            "infoIcon": "./textures/icons/info.svg",
            "linkIcon": "./textures/icons/link.svg",
            "modelIcon": "./textures/icons/model.svg",
            "videoIcon": "./textures/icons/video.svg"
        };
        this.rotationX = parsedJSONWidget.y;
        this.rotationY = parsedJSONWidget.x;
        this.focusThisWidget = parsedJSONWidget.focusOnThisWidget || false;
        this.onHoverText = parsedJSONWidget.widgetContent.onHoverText;
        this.title = parsedJSONWidget.widgetContent.title || null;
        this.type = null;
        this.content = parsedJSONWidget.widgetContent.content;
        if(this.content.length==1){
            this.type = this.content[0]._type;
        }
        this.widgetButton = null;
        this.widgetButtonMesh = null;
        this.widgetTitleText = null;
    }

    createWidgetButton(){
        
        this.widgetButton = this.createButton(this.title);
        this.widgetButtonMesh = BABYLON.MeshBuilder.CreatePlane("contentWidget", {width:0.9, height:0.8}, this.appManager.scene);
        
        let buttonADT = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(this.widgetButtonMesh);
        buttonADT.renderScale = 1;
        buttonADT.addControl(this.widgetButton);

        this.setWidgetLocation();
        this.setWidgetEvents();

        if(this.focusThisWidget){
            this.appManager.cameraManager.lookAtMeshSlowly(this.widgetButtonMesh);
        }
    }
    
    createButton(title){
        var button1 = BABYLON.GUI.Button.CreateSimpleButton("but1", "");
        button1.width = "1000px";
        button1.height = "1000px";
        button1.paddingBottomInPixels = 270;
        button1.paddingTopInPixels = 250;
        button1.paddingLeftInPixels = 250;
        button1.paddingRightInPixels = 250;
        button1.color = "transparent"
        button1.clipChildren = false;
        button1.clipContent = false;

        var ellipse1 = new BABYLON.GUI.Ellipse();
        ellipse1.width = "350px";
        ellipse1.height = "350px";
        //ellipse1.color = this.appManager.uiElements.colours.colourFadedLight25//"white";
        ellipse1.thickness = 0;
        ellipse1.background = this.appManager.uiElements.colours.colourFaded50;
        var ellipse2 = new BABYLON.GUI.Ellipse();
        ellipse2.width = "460px";
        ellipse2.height = "460px";
        ellipse2.thickness = 22;
        ellipse2.background = this.appManager.uiElements.colours.colourFadedBlack25;
        //ellipse2.addControl(ellipse1);
        button1.addControl(ellipse2);

       // button1.addControl(ellipse1);
        
        button1.addControl(this.createIcon());
        if(title !== null) {
            this.widgetTitleText = this.createText();
            button1.addControl(this.widgetTitleText);
        }

        button1.pointerDownAnimation = null;
        button1.pointerUpAnimation = null;

        return button1;
    }

    createIcon(){
        var icon = new BABYLON.GUI.Image("icon", this.getIconURL());
        icon.width = "240px";
        icon.height = "240px";
        return icon;
    }

    createText(){
        var text = new BABYLON.GUI.TextBlock("text",this.onHoverText);
        text.fontSizeInPixels = 140;
        text.color = "white";
        text.paddingTopInPixels = 730;
        text.widthInPixels = 1000;
        text.clipContent = false;
        text.textWrapping = true;
        text.resizeToFit = true;
        text.shadowBlur = 35;
        text.scaleX = 0.8;
        text.isVisible = true;
        text.alpha = 0;

        //TODO: revise gui animations
        text.animations = [];
        let alphaAnimation = new BABYLON.Animation("alphaAnimation", "alpha", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, 0);
        let alphaAnimationKeys = [
            { frame: 0, value: 0 }, 
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, value: 1 },
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES, value: 0 }
        ];
        alphaAnimation.setKeys(alphaAnimationKeys);
        text.animations.push(alphaAnimation);

        let positionAnimation = new BABYLON.Animation("positionAnimation", "paddingTopInPixels", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, 0);
        let animationShowPositionKeys = [
            { frame: 0, value: 600 },
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, value: 730 }, 
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES, value: 600 }
        ];
        positionAnimation.setKeys(animationShowPositionKeys);
        text.animations.push(positionAnimation);

        return text;
    }

    getIconURL(){
        if(this.icon != null)
            return this.icon;

        //TODO: use url builder
        let iconURL = "";
        switch(this.type) {
            case "video":
                iconURL = this.iconList.videoIcon;
                break;

            case "audio":
                iconURL = this.iconList.audioIcon;
                break;

            case "liveData":
                iconURL = this.iconList.dataIcon;
                break;

            /* case "website":
                iconURL = this.iconList.linkIcon;
                break; */

            case "photo":
                iconURL = this.iconList.imageIcon;
                break;

            case "model":
                iconURL = this.iconList.modelIcon;
                break;

            case "form":
                iconURL = this.iconList.formIcon;
                break;

            case "custom":
                iconURL = this.content;
                break;

            default:
                iconURL = this.iconList.infoIcon;
                break;
        }

        return iconURL;
    }

    setWidgetLocation(){
        let widgetLocation = new BABYLON.TransformNode("widgetLocation");

        widgetLocation.position = new BABYLON.Vector3(0, 0, 0);
        widgetLocation.rotation.x += Math.PI * (this.rotationX * 0.1);
        widgetLocation.rotation.y += Math.PI * (this.rotationY * 0.1);

        this.widgetButtonMesh.position.z = 8;
        this.widgetButtonMesh.parent = widgetLocation;
    }

    setWidgetEvents(){
        this.widgetButton.pointerEnterAnimation = () => {
            this.animateWidgetScaleTo(1.5);
            this.animateWidgetTitleTextVisible(true);
        }

        this.widgetButton.pointerOutAnimation = () => {
            this.animateWidgetScaleTo(1);
            this.animateWidgetTitleTextVisible(false);
        }

        if(this.type === "custom")
            return;
        
        switch(this.type) {
            case "model":
                this.widgetButton.onPointerClickObservable.add(() => this.focusOn3DModel());
                break;

/*             case "website":
                this.widgetButton.onPointerClickObservable.add(() => window.open(this.content[0].websiteURL));
                break; */
            
            case null:
                this.widgetButton.onPointerClickObservable.add(() => new PanelGroup(this.title, this.widgetGroup, this.content, this.type, this.appManager, true));
                break;

            default:
                this.widgetButton.onPointerClickObservable.add(() => new PanelGroup(this.title, this.widgetGroup, this.content, this.type, this.appManager, false));
                break;
        }
    }

    animateWidgetScaleTo(scale) {
        let ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        BABYLON.Animation.CreateAndStartAnimation('WidgetScaleX', this.widgetButtonMesh, 'scaling.x', 60, 10, this.widgetButtonMesh.scaling.x, scale, 0, ease);
        BABYLON.Animation.CreateAndStartAnimation('WidgetScaleY', this.widgetButtonMesh, 'scaling.y', 60, 10, this.widgetButtonMesh.scaling.y, scale, 0, ease);
    }

    animateWidgetTitleTextVisible(isVisible) {
        if(this.widgetTitleText) {
            if(isVisible) {
                this.appManager.scene.beginAnimation(
                    this.widgetTitleText, 
                    0, 
                    TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, 
                    false
                );
            }
            else {
                this.appManager.scene.beginAnimation(
                    this.widgetTitleText, 
                    TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, 
                    TITLE_TEXT_ANIMATION_TOTAL_FRAMES, 
                    false
                );
            }
        }
    }

    async focusOn3DModel(){
        if(this.appManager.is3DModelRendered)
            return;

        this.appManager.is3DModelRendered = true;
        
        var model = await BABYLON.SceneLoader.ImportMeshAsync("", this.content[0].modelURL);
        model.meshes[0].scaling = new BABYLON.Vector3(2, 2, 2);
        this.switchCamera(model);
    }

    switchCamera(model){
        this.appManager.spaceManager.currentSpace.setVisibilityOfAllHotspots(false);
        this.appManager.htmlElements.setVisible(this.appManager.htmlElements.hamburgerButton, false);
        this.appManager.htmlElements.setVisible(this.appManager.htmlElements.timelineButton, false);

        let onClose = () => {
            this.appManager.spaceManager.currentSpace.setVisibilityOfAllHotspots(true);
            this.appManager.htmlElements.setVisible(this.appManager.htmlElements.hamburgerButton, true);
            this.appManager.htmlElements.setVisible(this.appManager.htmlElements.timelineButton, true);
            this.appManager.scene.removeMesh(model);
            model.meshes[0].dispose();
            this.appManager.is3DModelRendered = false;
        };

        this.appManager.cameraManager.switchToCamera(
            this.appManager.cameraManager.createArcCamera(),
            onClose
        );
    }

    /* setWidgetScale(value){
        if(value == "moving"){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(0.4,0.4,0.4)
            this.setTitleTextVisibility(false);
        }
        else if(value == "hover" && this.widgetButtonMesh.scaling.x != 0.4){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1.2,1.2,1.2)
            this.setTitleTextVisibility(true);
        }
        else if(value == "unhover" && this.widgetButtonMesh.scaling.x != 0.4){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1)
            this.setTitleTextVisibility(false);
        }
        else if(value != "moving" && value != "hover" && value != "unhover"){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1)
            this.setTitleTextVisibility(false);
        }
    } */

    setTitleTextVisibility(isVisible){
        if(this.widgetTitleText)
            this.widgetTitleText.isVisible = isVisible;
    }

    dispose(){
        this.widgetButton.onPointerClickObservable.clear();
        this.widgetButton.dispose();
        this.widgetButtonMesh.parent.dispose();
        this.widgetButtonMesh.dispose(true, true);
    }
}